// react
import { createBrowserRouter, Navigate } from 'react-router-dom';

// store
import { Account } from '@karehero/models';

// views
// views - core
import Root from 'views/Root';
import RootMember from 'views/RootMember';
import RootAdmin from 'views/RootAdmin';
import SignIn from 'views/SignIn';
import Authenticate from 'views/Authenticate';
import Register from 'views/Register';
import InviteKeyRegistration from 'views/InviteKeyRegistration';
import Dashboard from 'views/Dashboard';
import DeleteAccount from 'views/DeleteAccount';

// views - admin
import WelcomeActions from 'views/WelcomeActions';
import ManageCareCircles from 'views/ManageCareCircles';
import NewCareCircle from 'views/NewCareCircle';
import ManageAccounts from 'views/ManageAccounts';
import NewAccount from 'views/NewAccount';
import ManageInvitedMembers from 'views/ManageInvitedMembers';

// views - settings
import Settings from 'views/Settings';

// views - action plan
import ActionPlan from 'views/ActionPlan';
import ActionPlanAll from 'views/ActionPlanAll';
import ActionPlanContent from 'views/ActionPlanContent';
import ActionPlanTable from 'views/ActionPlanTable';

// views - care circle
import CareCircle from 'views/CareCircle';
import CareCircleMemberInfo from 'views/CareCircleMemberInfo';
import NewCareCircleMember from 'views/NewCareCircleMember';
import CareCircleMember from 'views/CareCircleMember';

// views - articles
import ArticleBookmarks from 'views/ArticleBookmarks';
import ArticleOverview from 'views/ArticleOverview';
import ArticleContent from 'views/ArticleContent';

// views - services
import ServiceOverview from 'views/ServiceOverview';
import ServiceContent from 'views/ServiceContent';

// views - webinars
import WebinarOverview from 'views/WebinarOverview';
import WebinarContent from 'views/WebinarContent';

// views - onboarding
import OnboardingInvite from 'views/OnboardingInvite';
import OnboardingMemberRegister from 'views/OnboardingMemberRegister';
import OnboardingMemberCreate from 'views/OnboardingMemberCreate';
import OnboardingMemberAccountMigration from 'views/OnboardingMemberAccountMigration';
import OnboardingQuestions from 'views/OnboardingQuestions';

// views - care profile/plan
import CareProfile from 'views/CareProfile';
import CareProfileStart from 'views/CareProfileStart';
import CareProfileForm from 'views/CareProfileForm';
import CareProfileComplete from 'views/CareProfileComplete';
import CarePlan from 'views/CarePlan';
import CarePlanResidential from 'views/CarePlanResidential';
import CarePlanHome from 'views/CarePlanHome';
import CarePlanComplete from 'views/CarePlanComplete';
import CarePlanChoice from 'views/CarePlanChoice';
import CarePlanApprove from 'views/CarePlanApprove';
import CarePlanRequestChanges from 'views/CarePlanRequestChanges';
import KeyInformation from 'views/KeyInformation';

// view - care services
import OnboardingCareServices from 'views/OnboardingCareServices';

// views - directory
import Directory from 'directory/Directory';
import OnboardingDirectoryCareHome from 'views/OnboardingDirectoryCareHome';
import OnboardingDirectoryAtHome from 'views/OnboardingDirectoryAtHome';

// views - book call
import BookCall from 'views/BookCall';
import BookCallDetails from 'views/BookCallDetails';
import CannotBookCall from 'views/CannotBookCall';

// views - power of attorney
import PoaSuccess from 'views/PoaSuccess';

// views - extra
import IFrame from 'views/IFrame';

// views - help
import Help from 'views/Help';

// views - invite portal
import InvitePortal from 'views/InvitePortal';

// views - organization
import OrganizationEditor from 'views/OrganizationEditor';
import OrganizationTable from 'views/OrganizationTable';
import OrganizationMemberTable from 'views/OrganizationMemberTable';
import OrganizationTierTable from 'views/OrganizationTierTable';
import AttendanceAllowance from 'views/AttendanceAllowance';
import AttendanceAllowanceStart from 'views/AttendanceAllowanceStart';
import AttendanceAllowanceForm from 'views/AttendanceAllowanceForm';
import AttendanceAllowanceComplete from 'views/AttendanceAllowanceComplete';
import AttendanceAllowanceSuccess from 'views/AttendanceAllowanceSuccess';

interface CreateRouterOptions {
  isAuthenticated: boolean;
  isOnboarded: boolean;
  isOnboardingQuestionsCompleted: boolean;
  account?: Account;
  currentCareCircleId?: string;
  isCareCircle?: string;
  isAttendanceAllowance?: string;
  isBookCall?: boolean;
}

export const createRouter = (options: CreateRouterOptions) => {
  const isAdmin =
    options.account?.accountRole.name === 'KareHero Administrator' ||
    options.account?.accountRole.name === 'Care Concierge';
  const isOrganizationOwner =
    options.account?.accountRole.name === 'Organization Owner';

  const bookCallGuard = (
    TargetComponent: JSX.Element,
    isBookCall: boolean,
    isOnboardingQuestionsCompleted: boolean,
  ) => {
    if (!isBookCall) {
      return <CannotBookCall />;
    }

    if (!isOnboardingQuestionsCompleted) {
      return <Navigate to='/onboarding-questions' />;
    }

    return TargetComponent;
  };

  // if the user is not authenticated, only allow them to go to the sign in
  // page.
  if (!options.isAuthenticated) {
    return createBrowserRouter([
      {
        path: '/sign-in',
        element: <SignIn />,
      },
      {
        path: '/authenticate',
        element: <Authenticate />,
      },
      {
        path: '/authenticate/register',
        element: <Register />,
      },
      {
        path: '/register/organization/:organizationID',
        element: <InviteKeyRegistration />,
      },
      {
        path: '/onboarding',
        element: <OnboardingInvite />,
      },
      {
        path: '/register',
        element: <OnboardingMemberRegister />,
      },
      {
        path: '/register/member',
        element: <OnboardingMemberCreate />,
      },
      {
        path: '/migrate-account',
        element: <OnboardingMemberAccountMigration />,
      },
      // Account deletion
      {
        path: '/delete-account',
        element: <DeleteAccount />,
      },
      {
        path: '*',
        element: <Navigate to='/sign-in' />,
      },
    ]);
  }

  if (isOrganizationOwner) {
    return createBrowserRouter([
      {
        path: '/authenticate',
        element: <Authenticate />,
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '/',
            element: <InvitePortal />,
          },
          {
            path: '/settings',
            element: <Settings />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to='/' />,
      },
    ]);
  }

  if (!options.isOnboarded) {
    return createBrowserRouter([
      {
        path: '/onboarding',
        element: <OnboardingInvite />,
      },
      {
        path: '/register',
        element: <OnboardingMemberRegister />,
      },
      {
        path: '/register/member',
        element: <OnboardingMemberCreate />,
      },
      {
        path: '/delete-account',
        element: <DeleteAccount />,
      },
      {
        path: '*',
        element: <Navigate to='/register/member' />,
      },
    ]);
  }

  // if the user is authenticated, allow them to go to any page except the sign
  // in page.
  return createBrowserRouter([
    // sign in
    {
      path: '/authenticate',
      element: <Authenticate />,
    },
    {
      path: '/sign-in',
      element: <Navigate to={isAdmin ? '/admin' : '/'} />,
    },
    {
      path: '/register/member',
      element: <OnboardingMemberCreate />,
    },

    // onboarding
    {
      path: '/onboarding',
      element: <Navigate to='/' />,
    },

    // redirect to root if the user is not in a care circle
    {
      path: '/care-circle/undefined',
      element: <Navigate to='/' />,
    },
    {
      path: '/care-circle/undefined/:wildcard',
      element: <Navigate to='/' />,
    },

    // Account deletion
    {
      path: '/delete-account',
      element: <DeleteAccount />,
    },

    // root
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '/admin',
          element: <RootAdmin />,
          children: [
            // action plan
            {
              path: '/admin/action-plan',
              element: <ActionPlanTable />,
            },

            // root - admin or user
            {
              path: '/admin',
              element: <WelcomeActions />,
            },

            // care circle
            {
              path: '/admin/care-circle/manage',
              element: <ManageCareCircles />,
            },
            {
              path: '/admin/care-circle/new',
              element: <NewCareCircle />,
            },

            // account
            {
              path: '/admin/account/manage',
              element: <ManageAccounts />,
            },
            {
              path: '/admin/account/new',
              element: <NewAccount />,
            },

            // invited member
            {
              path: '/admin/invited-member/manage',
              element: <ManageInvitedMembers />,
            },

            // organization
            {
              path: '/admin/organization/manage',
              element: <OrganizationTable />,
            },
            {
              path: '/admin/organization/:organizationID/tiers',
              element: <OrganizationTierTable />,
            },
            {
              path: '/admin/organization/new',
              element: <OrganizationEditor />,
            },
            {
              path: '/admin/organization/:organizationID/edit',
              element: <OrganizationEditor />,
            },
            {
              path: '/admin/organization/:organizationID/member',
              element: <OrganizationMemberTable />,
            },
          ],
        },
        // settings
        {
          path: '/settings',
          element: <Settings />,
        },
        {
          path: '/',
          element: <RootMember />,
          children: [
            // dashboard
            {
              path: '/',
              element: <Dashboard />,
            },

            // settings
            {
              path: '/settings',
              element: <Settings />,
            },

            // help
            {
              path: '/help',
              element: <Help />,
            },

            // action plan
            {
              path: '/action-plan',
              element: <ActionPlan />,
            },
            {
              path: '/action-plan/all',
              element: <ActionPlanAll />,
            },
            {
              path: '/action-plan/:actionPlanItemId',
              element: <ActionPlanContent />,
            },

            // care services
            {
              path: '/care-services',
              element: <OnboardingCareServices />,
            },

            // care circle
            {
              path: '/care-circle',
              element: <CareCircleMember />,
            },
            {
              path: '/care-circle/member/:accountID',
              element: <CareCircleMemberInfo />,
            },

            // care profile
            {
              path: '/care-profile',
              element: <CareProfile />,
            },
            {
              path: '/care-profile/start',
              element: <CareProfileStart />,
            },
            {
              path: '/care-profile/:careProfileFormId',
              element: <CareProfileForm />,
            },
            {
              path: '/care-profile/complete',
              element: <CareProfileComplete />,
            },
            {
              path: '/key-information',
              element: <KeyInformation />,
            },

            // power of attorney
            {
              path: '/poa-success',
              element: <PoaSuccess />,
            },

            // attendance allowance
            ...(options.isAttendanceAllowance
              ? [
                  {
                    path: '/attendance-allowance',
                    element: <AttendanceAllowance />,
                  },
                  {
                    path: '/attendance-allowance/start',
                    element: <AttendanceAllowanceStart />,
                  },
                  {
                    path: '/attendance-allowance/:careProfileFormId',
                    element: <AttendanceAllowanceForm />,
                  },
                  {
                    path: '/attendance-allowance/complete',
                    element: <AttendanceAllowanceComplete />,
                  },
                  {
                    path: '/attendance-allowance/success',
                    element: <AttendanceAllowanceSuccess />,
                  },
                ]
              : [
                  {
                    path: '/attendance-allowance/start',
                    element: (
                      <Navigate to='/library/claim-attendance-allowance' />
                    ),
                  },
                ]),
            // care plan
            {
              path: '/care-plan',
              element: <CarePlan />,
            },
            {
              path: '/care-plan/approve',
              element: <CarePlanApprove />,
            },
            {
              path: '/care-plan/request-changes',
              element: <CarePlanRequestChanges />,
            },
            {
              path: '/care-plan/:careProfileFormId',
              element: <CareProfileForm />,
            },
            ...(isAdmin
              ? [
                  {
                    path: '/care-plan/choice',
                    element: <CarePlanChoice />,
                  },
                  {
                    path: '/care-plan/home',
                    element: <CarePlanHome />,
                  },
                  {
                    path: '/care-plan/residential',
                    element: <CarePlanResidential />,
                  },
                  {
                    path: '/care-plan/complete',
                    element: <CarePlanComplete />,
                  },
                ]
              : []),

            // care circle
            ...(options.isCareCircle
              ? [
                  {
                    path: '/circle',
                    element: <CareCircle />,
                  },
                ]
              : []),
            {
              path: '/new',
              element: <NewCareCircleMember />,
            },

            // articles
            {
              path: '/library/bookmarks',
              element: <ArticleBookmarks />,
            },
            {
              path: '/library',
              element: <ArticleOverview />,
            },
            {
              path: '/library/:articleId',
              element: <ArticleContent />,
            },

            // services
            {
              path: '/service',
              element: <ServiceOverview />,
            },
            {
              path: '/service/:serviceId',
              element: <ServiceContent />,
            },

            // webinars
            {
              path: '/webinar',
              element: <WebinarOverview />,
            },
            {
              path: '/webinar/:webinarId',
              element: <WebinarContent />,
            },

            // prototypes
            {
              path: '/library/prototype',
              element: (
                <IFrame src='https://www.figma.com/proto/YsQcwxdw1eASV4y9r010s2/Web-App-Slice-2%3A-Content-library-%E2%80%A8%26-service-cards?embed_host=share&kind=&node-id=537-69478&page-id=75%3A43188&scaling=contain&starting-point-node-id=136%3A24163&type=design&viewport=1836%2C3964%2C0.51&hide-ui=1' />
              ),
            },
            {
              path: '/prototype',
              element: (
                <IFrame src='https://www.figma.com/proto/GIjunTSsNfqsajgmOC6bu1/Web-app---Slice-1%3A-Care-Profile?embed_host=share&page-id=1470%3A95639&type=design&node-id=1470-96993&viewport=2486%2C309%2C0.1&scaling=contain&starting-point-node-id=1470%3A96993&hide-ui=1' />
              ),
            },

            // book-call
            {
              path: '/book-call/details',
              element: bookCallGuard(
                <BookCallDetails />,
                !!options.isBookCall,
                options.isOnboardingQuestionsCompleted,
              ),
            },

            {
              path: '/book-call',
              element: bookCallGuard(
                <BookCall />,
                !!options.isBookCall,
                options.isOnboardingQuestionsCompleted,
              ),
            },

            ...(options.isOnboardingQuestionsCompleted
              ? []
              : [
                  {
                    path: '/onboarding-questions',
                    element: <OnboardingQuestions />,
                  },
                ]),

            {
              path: '*',
              element: <Navigate to='/' />,
            },

            // directory
            {
              path: '/directory',
              element: <Directory />,
            },
            {
              path: '/directory/care-home',
              element: <OnboardingDirectoryCareHome />,
            },
            {
              path: '/directory/at-home',
              element: <OnboardingDirectoryAtHome />,
            },
          ],
        },
      ],
    },
  ]);
};
