// react
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile } from 'hooks';

// pdf
import { careProfileDocDefinition } from 'pdf';

// components
import {
  CareProfileComplete as CareProfileCompletePage,
  CareProfileRoot,
} from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * CareProfileComplete is the dashboard page.
 */
const CareProfileComplete = () => {
  // hooks
  const { track } = useMixpanel();
  const {
    numCompleted,
    numTotal,
    careCircle,
    answers,
    careProfileCategories,
    sections,
  } = useCareProfile();
  const navigate = useNavigate();

  // memos
  const careRecipientName = useMemo(
    () =>
      `${careCircle?.careRecipientAccount?.firstName || ''} ${careCircle?.careRecipientAccount?.lastName || ''}`,
    [careCircle],
  );

  // methods
  const handlePrintPdf = useCallback(() => {
    if (!careProfileCategories) return;
    careProfileDocDefinition(
      careProfileCategories,
      answers,
      careRecipientName,
    ).open();
  }, [careProfileCategories, answers, careRecipientName]);

  // effects
  useEffect(() => {
    track(MixpanelEvent.CarePlanComplete, {});
  }, [track]);

  return (
    <CareProfileRoot
      backLabel='Back to care plan'
      nextLabel='Book your call back'
      onPrintPdf={handlePrintPdf}
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      toExit='/care-profile?tab=2'
      onNext={() => navigate(`/book-call/details`)}
      toPrevious='/care-profile?tab=2'
    >
      <CareProfileCompletePage
        email={process.env.REACT_APP_SUPPORT_EMAIL}
        isComplete={numCompleted === numTotal}
        toBookCall={`/book-call/details`}
      />
    </CareProfileRoot>
  );
};

export default CareProfileComplete;
