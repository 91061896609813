// react
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// store
import { useLazyGetServiceQuery } from 'store/api/cms';
import { Service as ServiceType } from '@karehero/models';

// components
import { ServiceContent as ServiceContentPage } from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * Service shows the content of a Service from Sanity.
 */
const Service = () => {
  // state
  const [service, setService] = useState<ServiceType>();

  // hooks
  const { track } = useMixpanel();
  const { careCircleId, serviceId } = useParams();
  const [getService, { isLoading: isServiceLoading }] =
    useLazyGetServiceQuery();
  const navigate = useNavigate();

  // effects
  useEffect(() => {
    if (!serviceId) {
      navigate(`/service`);
      return;
    }
    (async () => {
      const { data, error } = await getService(serviceId);
      if (error) {
        navigate(`/service`);
        return;
      }
      if (!data) return;
      setService(data);

      track(MixpanelEvent.ServiceViewed, {
        id: data._id,
        title: data.title,
      });
    })();
  }, [serviceId, careCircleId, getService, navigate, track]);

  return (
    <ServiceContentPage
      content={service}
      isLoading={!service ? true : isServiceLoading}
      navigate={navigate}
      backPath={`/service`}
      onAction={() => navigate(`/book-call/details`)}
    />
  );
};

export default Service;
