// react
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { CarePlanRequestChanges as CarePlanRequestChangesPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

// store
import { useRequestChangesCarePlanMutation } from 'store/api/carePlan';

/**
 * CarePlanRequestChanges shows the care plan complete page.
 */
const CarePlanRequestChanges = () => {
  // state
  const [request, setRequest] = useState('');

  // hooks
  const { careCircle } = useCareProfile();
  const [requestChangesCarePlan] = useRequestChangesCarePlanMutation();
  const navigate = useNavigate();

  return (
    <CarePlanRequestChangesPage
      onRequestChanges={async () => {
        await requestChangesCarePlan({
          careCircleId: careCircle?.id || '',
          request: request,
        });
        navigate(`/care-profile?tab=3`);
      }}
      onBookCall={() => navigate(`/book-call/details`)}
      value={request}
      onChange={setRequest}
    />
  );
};

export default CarePlanRequestChanges;
