import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Button, CardColor, Title } from "../../atoms";
import { CardContainer } from "../../templates";
// assets
// @transform-path ../../../../../../src/assets/keni/call.svg
import readingBook from "../../../../../../src/assets/keni/call.svg";
/**
 * Help renders the help page.
 */
export const Help = ({ className, careCircleId }) => {
    // memos
    const config = useMemo(() => [
        {
            title: 'Question about care?',
            body: 'Our friendly care experts are on hand to answer care questions. You can either speak to them on the phone, or via the live chat in the bottom right hand corner of your app.',
            color: 'green',
            to: `/book-call/details`,
            buttonLabel: 'Book a call',
            buttonVariant: 'primary',
        },
        {
            title: 'Read FAQs',
            body: 'Got a question about the KareHero app? Check out our FAQs to see if others are asking the same question.',
            color: 'blue',
            to: `/service/faq`,
            buttonLabel: 'See more',
            buttonVariant: 'secondary',
        },
    ], [careCircleId]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Help" })), _jsx(ImageWrapper, { children: _jsx("img", { alt: 'header', src: readingBook }) }), _jsx(CardContainer, Object.assign({ maxColumns: 1, isPreventHeightMatch: true }, { children: config.map((configItem) => (_jsx(CardColor, Object.assign({ color: configItem.color }, { children: _jsxs(Content, { children: [_jsx(CardTitle, { children: configItem.title }), _jsx(CardBody, { children: configItem.body }), _jsx(StyledButton, Object.assign({ ariaLabel: configItem.buttonLabel, to: configItem.to, variant: configItem.buttonVariant }, { children: configItem.buttonLabel }))] }) }), configItem.title))) }))] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
const CardTitle = styled.div `
  width: 100%;
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const CardBody = styled.div `
  width: 100%;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
  width: 100%;
`;
const ImageWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  height: 192px;
  max-height: 192px;
  overflow: visible;

  ${(props) => props.theme.breakpoint.md(css `
      width: auto;
      object-fit: contain;
      min-height: 256px;
      height: 256px;
      max-height: 256px;
    `)}

  & > img {
    border-radius: 8px;
    width: 300px;
    max-width: 300px;
    height: 100%;
    object-fit: contain;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const StyledButton = styled(Button) `
  width: 200px;
  margin-top: ${(props) => props.theme.spacing[24]};
`;
Help.displayName = 'Help';
export default Help;
